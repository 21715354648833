import React, {Suspense} from 'react';
import AppData from './appData.js';
import Application from './application.js';
import './applications.css';
const LazyImages = React.lazy(() => import('./lazyImages.js'))

const Applications = () => {

var appSections = AppData.map(application => (
  <Application key={application.name} appDatas={application} />
))


    return (
      <div className="applications" >
        <div className="sectionBar">
          <div className="sectionTitle">Applications</div>
        </div>
        {appSections}
        <Suspense fallback={<div>Loading...</div>}>
          <LazyImages />
        </Suspense>
      </div>
    )
}

export default Applications;