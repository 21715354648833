import React, {useRef, useState, useEffect} from 'react';

const Application = ({ appDatas }) => {
  const { name, techs, stillClass, aniClass, description, links } = appDatas;

  const techList = techs.map(tech =>
    <span className="appTech" key={tech} > {tech} </span>
  )

  const splitDescription = description.split('\n').map((item, i) => {
    return <p key={i}>{item}<br/></p>;
});

  const linkList = links.map( (link, i) =>
    <div className='appLink ' key={i} onClick={()=> window.open(`${link[1]}`, "_blank")}>{link[0]}</div>
  )

  const thisRef = useRef(null);
  const [animate, setAnimate] = useState(false);


  const gifSwitch = function(e) {
    let appPos = thisRef.current.offsetTop - window.pageYOffset;
    setAnimate(appPos < 370 && appPos > -145);
  }

  const debounce = (func, delay) => {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
            clearTimeout(debounceTimer)
                debounceTimer
            = setTimeout(() => func.apply(context, args), delay)
    }
  }

   useEffect(() => {
     window.addEventListener('scroll', debounce(gifSwitch,100))
    });

  return (
    <React.Fragment>
      <div className='appTitle' ref={thisRef} onScroll={gifSwitch}>{name}</div>
      <div className='appBody'>
        <div className={animate ? `${aniClass} appImage` : `appImage ${stillClass}`}/>
        <div className='appText'>

          <div className='appTechs'>
            <div className='appSection'>Technologies used: </div>
            {techList} <br/>
          </div>
          <div className='appSection'>Description: </div>
          {splitDescription} <br/>
          <div className='appTechs'>
            <span className='appSection'>Links: </span>{linkList}
          </div>

        </div>
      </div>
    </React.Fragment>

  )
}
export default Application;