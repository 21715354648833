import React, { Suspense } from 'react'
import './resume.css'
const PDFViewer = React.lazy(() => import('pdf-viewer-reactjs'))

const Resume = () => {
    return (
        <div className="container">
            <div className="sectionBar">
                <div className="sectionTitle">Resume</div>
            </div>
          <div className="dlButtons">
          <a href='./DarylMarcoResume.pdf' download='DarylMarcoResume.pdf' > <button>Download as PDF</button></a>
          <a href='./DarylMarcoResume.docx' download='DarylMarcoResume.docx' > <button> Download as Word Document</button></a>
          </div>
          <div className='innerContainer'>
          <Suspense fallback={<div>Loading...</div>}>
        <PDFViewer
            document={{
                url: './DarylMarcoResume.pdf',
            }}
            scale={1.25}
            hideNavbar={true}
        />
        </Suspense>
        <br/>
        </div>
        <br/>
        <br/>
        </div>

    )
}

export default Resume;