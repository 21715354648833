const AppData = [
  {
    name: 'SofaWorks',
    prefix: 'Sofa',
    techs: ['React', 'Styled Components', 'CSS', 'MySQL', 'Node', 'Webpack', 'AWS',],
    stillClass: 'appISofa',
    aniClass: 'appIGoSofa',
    description: 'I worked as part of an agile remote team to develop the product page of an online furniture retailer. My responsibility was to create the review section and product details sections, as well as to develop a proxy to deploy the other microservices. I used Styled Components for the animations and MySQL to handle the data. Microservices and proxy were deployed to Amazon Web Services Elastic Containers.',
    links:  [
      [
      'GitHub', 'https://github.com/FEC-HRR41-PICOLLO/darjama-ikea-reviews',
      ],
    ]
  },
  {
    name: 'ConnectSquares',
    techs: ['React', 'React Native', 'CSS', 'Expo-CLI',],
    stillClass: 'appIC4',
    aniClass: 'appIGoC4',
    description: 'A few months ago my kids became obsessed with the classic Connect Four game but kept losing the pieces. They asked me to create a version they could play without losing the pieces. So I coded a simple React version they could play on my computer. \n \n When they wanted to play it on my phone I took it as an opportunity to use React Native to make a version that could be played offline. Using the Expo command line interface I published an APK that could be installed on android phones for offline play.',
    links: [
      [
      'GitHub', 'https://github.com/darjama/connectSquares/'
      ],[
        'Android Apk', 'https://drive.google.com/file/d/19fazW-fDKXLvn2LA8SgnNM4QBUpuoGVh/view?usp=sharing',
      ]
    ],
  },
  {
    name: 'Your New Fave',
    techs: ['React', 'Node', 'Express', 'AWS', 'MongoDB', 'APICache', 'HTML', 'CSS'],
    stillClass: 'appIYnf',
    aniClass: 'appIGoYnf',
    description: `I always like to find new music to listen to while coding. This single page application integrates data from lastFM and YouTube APIs to create recommendations for discovering new music videos to watch based on a similar artist search. \n
    To make sure that I don't exceed my YouTube API usage quota I set up an LRU cache to serve common search results. Favorites are saved using a MongoDB database.`,
    links: [ [
      'GitHub', 'https://github.com/darjama/yourNewFave',
  ]
    ],
  },
  {
    name: 'Craftworkery',
    techs: ['React', 'Node', 'Express', 'AWS', 'CloudFront', 'PostgreSQL', 'HTML', 'CSS', 'New Relic', 'Loader.io'],
    stillClass: 'appICraft',
    aniClass: 'appIGoCraft',
    description: `For this project I deployed a photo carousel microservice for the product page of an online store selling bespoke products as part of distributed remote team using Agile practices with daily standups.

    The PostGRES photo database contained over ten million products with 22 million product images. This was deployed to AWS Elastic Containers with round-robin load balancing to improve the response times under heavier loads.`
    ,
    links: [ [
      'GitHub', 'https://github.com/SDC-PepperJack/Pepperjack-carousel-darjama',
  ]
    ],
  },
]


export default AppData;